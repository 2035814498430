<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="vqb-rule bg-gray-100 rounded border border-gray-300 mb-3 p-3">
    <div class="flex flex-wrap items-center">
      <label class="mr-5">{{ rule.label }}</label>

      <!-- List of operands (optional) -->
      <select
        v-if="typeof rule.operands !== 'undefined'"
        v-model="query.operand"
        class="
          inline-block
          w-auto
          h-10
          px-1
          py-2
          leading-normal
          gray-500
          border border-gray-300
          rounded
          mr-2
        "
      >
        <option v-for="operand in rule.operands" :key="operand">
          {{ operand }}
        </option>
      </select>

      <!-- List of operators (e.g. =, !=, >, <) -->
      <select
        v-if="
          typeof rule.operators !== 'undefined' && rule.operators.length > 1
        "
        v-model="query.operator"
        class="
          inline-block
          w-auto
          h-10
          px-1
          py-2
          leading-normal
          gray-500
          border border-gray-300
          rounded
          mr-2
        "
      >
        <option
          v-for="operator in rule.operators"
          :key="operator"
          :value="operator"
        >
          {{ labels.operatorsMapping[operator] || operator }}
        </option>
      </select>

      <!-- Basic text input -->
      <multi-value
        v-if="rule.inputType === 'text'"
        v-model="query.value"
        class="
          inline-block
          w-auto
          px-1
          py-2
          leading-normal
          gray-500
          border border-gray-300
          rounded
        "
        :placeholder="labels.textInputPlaceholder"
      />

      <template v-if="rule.inputType === 'custom-component'">
        <component
          :is="rule.component"
          v-bind="rule.componentProps"
          v-model="query.value"
        ></component>
      </template>

      <!-- Remove rule button -->
      <button
        type="button"
        class="close ml-auto"
        @click="remove"
        v-html="labels.removeRule"
      ></button>
    </div>
  </div>
</template>

<script>
import QueryBuilderRule from "vue-query-builder/src/components/QueryBuilderRule.vue";
import MultiValue from "./MultiValue.vue";

export default {
  extends: QueryBuilderRule,

  components: { MultiValue },

  props: {
    rule: {
      componentProps: {
        type: Object,
      },
    },
  },
};
</script>
