<template>
  <vs-card>  
    <div class="flex-row d-flex">
      <div class="" v-for="(sub, key) in subscribers" :key="key">
        <b-card :title="key" img-src="" img-alt="" img-top tag="article" style="max-width: 20rem;" class="mb-2">
          <b-card-text>
            <b>{{ sub.total }}</b>
          </b-card-text>
        </b-card>
      </div>
    </div>
    <div class="row">
      <form autocomplete="off" @submit.prevent="submit" class="col">
        <div class="form-group row justify-content-center">
          <label for="publisher" class="text-right col-sm-4 col-form-label">Publisher</label>
          <div class="col-sm-6">
            <select id="publisher" required v-model="publisher" class="form-control">
              <option :value="opt.id" v-for="opt in publishers" :key="opt.id">{{ opt.name }}</option>
            </select>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="sendType" class="text-right col-sm-4 col-form-label">Send Type</label>
          <div class="col-sm-6">
            <select id="sendType" required v-model="sendType" class="form-control">
              <option :value="opt" v-for="opt in sendTypes" :key="opt">{{ opt }}</option>
            </select>
          </div>
        </div>
        <div class="form-group row justify-content-center" v-if="sendType == 'audiences'">
          <label for="audiences" class="text-right col-sm-4 col-form-label">Audiences</label>
          <div class="col-sm-6">
            <multiselect
              v-model="audiences"
              label="name"
              track-by="name"
              :options="audiencesOptions"
              :multiple="true"
              :searchable="true"
            ></multiselect>
          </div>
        </div>
        <div class="form-group row justify-content-center" v-if="sendType == 'individual'">
          <label for="payload" class="text-right col-sm-4 col-form-label">Payload</label>
          <div class="col-sm-6">
            <input class="form-control" type="payload" id="payload" required v-model="payload" placeholder="Push Payload" />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="url" class="text-right col-sm-4 col-form-label">URL</label>
          <div class="col-sm-6">
            <input class="form-control" type="url" id="url" required v-model="url" placeholder="URL Address" />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="title" class="text-right col-sm-4 col-form-label">Title</label>
          <div class="col-sm-6">
            <input class="form-control" type="title" id="title" required v-model="title" placeholder="Title" />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="body" class="text-right col-sm-4 col-form-label">Body</label>
          <div class="col-sm-6">
            <input class="form-control" type="body" id="body" required v-model="body" placeholder="Message Body" />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="pic" class="text-right col-sm-4 col-form-label">Image URL</label>
          <div class="col-sm-6">
            <input class="form-control" type="pic" id="pic" required v-model="pic" placeholder="Image URL" />
          </div>
        </div>
        <vs-button @click="submit" :disabled="inProgress" color="primary">{{ inProgress ? 'Sending...' : 'Send Push' }}</vs-button>
      </form>
    </div>
  </vs-card>
</template>

<script>
import Multiselect from "vue-multiselect";
import { PUBLISHERS } from '../../utils/product_entities';

  export default {
    data() {
      return {
        subscribers: {},
        topicSubscribers: 0,
        inProgress: false,
        url: null,
        title: null,
        body: null,
        pic: null,
        payload: null,
        audiencesOptions: [],
        audiences: [],
        stats: null,
        publisher: null,      
        publishers: PUBLISHERS,
        sendType: "audiences",
        sendTypes: ["audiences", "individual"]
      }
    },
    watch: {
      publisher: function(pub) {
        this.audiences = [];
        this.audiencesOptions = [];
        this.$http.get(`${this.host}/push/audiences`, { params: { publisher: pub } }).then(res => {
          this.audiencesOptions = res.data.audiences;
        });
      },
    },
    methods: {
      submit() {
        if (!document.forms[0].reportValidity()) return;

        this.$http.post(
          `${this.host}/push/${this.publisher}/send_push`, {
            send_type: this.sendType,
            audience_ids: this.audiences.map(c => c._id),
            title: this.title,
            body: this.body,
            url: this.url,
            pic: this.pic,
            payload: this.payload
          })
      },
    },
    components: {
      Multiselect
    }
  }
</script>

<style>

</style>
