<template>
  <div
    class="
      vqb-group
      relative
      flex flex-col
      bg-white
      rounded
      border border-gray-300
      mb-3
    "
    :class="'depth-' + depth.toString()"
  >
    <div
      class="vqb-group-heading rounded-t bg-gray-300 border-b border-gray-400"
    >
      <div
        class="match-type-container form-inline vqb-group-heading card-header"
      >
        <label class="mr-2" for="vqb-match-type">{{ labels.matchType }}</label>

        <select
          id="vqb-match-type"
          v-model="query.logicalOperator"
          class="
            inline-block
            w-auto
            mr-10
            h-10
            px-1
            py-2
            leading-normal
            gray-500
            border border-gray-300
            rounded
          "
        >
          <option
            v-for="label in labels.matchTypes"
            :key="label.id"
            :value="label.id"
          >
            {{ label.label }}
          </option>
        </select>

        <div v-if="withRange && query.range" class="d-flex flex-row">
          <multi-mode-range
            v-model="query.range.start"
            :eventsOptions="customRangeOptions"
          />

          <feather-icon class="mr-4 ml-4" icon="ArrowRightIcon" />

          <multi-mode-range
            v-model="query.range.end"
            :eventsOptions="customRangeOptions"
          />
        </div>

        <button
          v-if="depth > 1"
          type="button"
          class="close ml-auto"
          @click="remove"
          v-html="labels.removeGroup"
        ></button>
      </div>
    </div>

    <div class="vqb-group-body flex-auto p-5 mb-px">
      <div class="mb-5 flex flex-wrap items-center">
        <div class="form-group flex items-center flex-none">
          <select
            v-model="selectedRule"
            class="
              inline-block
              w-auto
              mr-2
              h-10
              px-1
              py-2
              leading-normal
              gray-500
              border border-gray-300
              rounded
            "
          >
            <option v-for="rule in rules" :key="rule.id" :value="rule">
              {{ rule.label }}
            </option>
          </select>

          <button
            :disabled="!selectedRule"
            type="button"
            class="text-white px-3 py-2 mr-2 rounded btn btn-secondary"
            @click="addRule"
          >
            {{ labels.addRule }}
          </button>

          <button
            v-if="depth < maxDepth"
            type="button"
            class="text-white px-3 py-2 rounded btn btn-secondary"
            @click="addGroup"
          >
            {{ labels.addGroup }}
          </button>
        </div>
      </div>

      <query-builder-children v-bind="$props" />
    </div>
  </div>
</template>

<script>
import QueryBuilderGroup from "vue-query-builder/src/components/QueryBuilderGroup.vue";

import QueryBuilderChildren from "./QueryBuilderChildren.vue";
import QueryBuilderRule from "./QueryBuilderRule.vue";
import MultiModeRange from "./MultiModeRange.vue";
import rangeHelper from "./range-helper";

export default {
  name: "QueryBuilderGroup",

  mixins: [rangeHelper],

  extends: QueryBuilderGroup,

  inject: ["withRange", "customRangeOptions"],

  components: {
    QueryBuilderChildren,
    QueryBuilderRule,
    MultiModeRange,
  },

  data() {
    return {
      selectedRule: undefined,
      isRangeStartOpen: false,
      isRangeEndOpen: false,
    };
  },

  methods: {
    toggleRangeStart() {
      this.isRangeStartOpen = !this.isRangeStartOpen;
      this.isRangeEndOpen = false;
    },

    toggleRangeEnd() {
      this.isRangeEndOpen = !this.isRangeEndOpen;
      this.isRangeStartOpen = false;
    },

    addGroup() {
      if (this.withRange) {
        // Add range functionality to group through event listener

        const addRangeToGroup = (group) => {
          if (group.range) return;

          Object.assign(group, {
            range: {
              start: this.createRange(0),
              end: this.createRange(0),
            },
          });
        };

        this.$once("update:query", (query) => {
          recursiveEachGroup(query, addRangeToGroup);
        });
      }

      QueryBuilderGroup.methods.addGroup.call(this);
    },
  },
};

function recursiveEachGroup(query, fn) {
  if (!query.children) return;

  for (const child of query.children) {
    recursiveEachGroup(child.query, fn);
  }

  fn(query);
}
</script>

<style>
.vue-query-builder .vqb-group.depth-1 .vqb-rule,
.vue-query-builder .vqb-group.depth-2 {
  border-left: 2px solid #8bc34a;
}

.vue-query-builder .vqb-group.depth-2 .vqb-rule,
.vue-query-builder .vqb-group.depth-3 {
  border-left: 2px solid #00bcd4;
}

.vue-query-builder .vqb-group.depth-3 .vqb-rule,
.vue-query-builder .vqb-group.depth-4 {
  border-left: 2px solid #ff5722;
}

.vue-query-builder .close {
  opacity: 1;
  color: rgb(150, 150, 150);
}

@media (min-width: 768px) {
  .vue-query-builder .vqb-rule.form-inline .form-group {
    display: block;
  }
}
</style>
