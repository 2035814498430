<template>
  <vs-card>
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>

    <b-form class="p-8" @submit.prevent="onSubmit">
      <b-form-select
        class="mb-4"
        required
        v-model="form.platform"
        :options="platforms"
      />

      <b-form-select
        class="mb-4"
        v-model="form.device_type"
        :options="deviceTypes"
      />

      <b-form-select
        required
        class="mb-4"
        v-model="form.layout"
        :options="layouts"
      />

      <b-form-group
        id="input-group-end-of-article"
        label-for="end-of-article"
        v-if="selectedLayout.required.eoa"
        description="Element to display after EOA"
      >
        <b-form-select
          id="end-of-article"
          v-model="form.end_of_article"
          :options="endOfArticleOptions"
          required
        />
      </b-form-group>

      <b-form-group
        id="input-group-sticky-display"
        class="mt-2"
        label="Sticky/Footer:"
      >
        <b-form-checkbox
          switch
          size="lg"
          class="mb-4"
          v-model="stickyEnabled"
          id="sticky_enabled"
        />

        <b-form-input
          id="input-sticky-display-unit_start"
          v-model.number="form.sticky_display"
          type="number"
          min="1"
          :disabled="!stickyEnabled"
          required
          placeholder="Enter sticky unit display"
        />
        <label class="small" for="input-sticky-display-unit_start"
          >Unit number where sticky ad displayed</label
        >

        <b-form-input
          id="input-sticky-display-unit_refresh"
          class="mt-2"
          v-model.number="form.sticky_unit_refresh"
          type="number"
          min="1"
          :required="form.sticky_time_refresh == null"
          :disabled="!stickyEnabled"
          placeholder="Enter sticky unit refresh"
        />
        <label class="small" for="input-sticky-display-unit_start"
          >Refresh every X items</label
        >

        <b-form-input
          id="input-sticky-display-unit_refresh"
          class="mt-2"
          v-model.number="form.sticky_time_refresh"
          type="number"
          min="10"
          max="60"
          step="10"
          :required="form.sticky_unit_refresh == null"
          :disabled="!stickyEnabled"
          placeholder="Enter sticky time refresh"
        />
        <label class="small" for="input-sticky-display-unit_start"
          >Refresh every X seconds</label
        >
      </b-form-group>

      <b-form-group
        id="input-group-inter"
        class="mt-2"
        label="Interstital display:"
        label-for="input-inter"
        description="Unit number where interstitial ad displayed"
      >
        <b-form-input
          id="input-inter"
          v-model.number="form.inter"
          type="number"
          min="0"
          :disabled="!selectedLayout.required.inter"
          placeholder="Enter interstitial display"
        />
      </b-form-group>

      <b-form-group
        id="input-group-single-ad-display"
        class="mt-2"
        label="Single ad:"
        label-for="input-single-ad-display"
        description="Unit number where single banner ads start"
      >
        <b-form-input
          id="input-single-ad-display"
          v-model.number="form.single_ad"
          type="number"
          min="-1"
          :disabled="!selectedLayout.required.single_ad"
          placeholder="Enter single ad"
        />
      </b-form-group>

      <b-form-group
        id="input-group-double-ad-display"
        class="mt-2"
        label="Double ad:"
        label-for="input-double-ad-display"
        description="Unit number where double banner ads start"
      >
        <b-form-input
          id="input-double-ad-display"
          v-model.number="form.double_ad"
          type="number"
          min="-1"
          :disabled="!selectedLayout.required.double_ad"
          placeholder="Enter double ad"
        />
      </b-form-group>

      <b-form-group
        id="input-group-video-player"
        label="Video player:"
        label-for="video-player"
      >
        <b-form-select
          id="video-player"
          v-model="form.video_player"
          :options="videoPlayerOptions"
        />
      </b-form-group>

      <b-form-group
        id="input-group-custom"
        label="Custom (JSON format snake_case keys):"
        label-for="input-custom"
      >
        <b-form-textarea
          id="input-custom"
          v-model="form.custom"
          type="text"
          rows="5"
          max-rows="8"
          placeholder="Enter custom"
        ></b-form-textarea>
      </b-form-group>

      <hr />

      <b-form-checkbox
        id="checkbox-is-default"
        v-model="form.is_default"
        name="checkbox-is-default"
        >Is default</b-form-checkbox
      >

      <b-form-group id="input-group-name" label="Name:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          type="text"
          required
        ></b-form-input>
      </b-form-group>

      <b-button
        type="submit"
        variant="primary"
        class="mt-8"
        :disabled="inProgress"
        >Create preset</b-button
      >
    </b-form>
  </vs-card>
</template>

<script>
import platformOptions from "../../../src/utils/platform_options";
import {
  VIDEO_PLAYER_OPTIONS,
  LAYOUT_OPTIONS,
  END_OF_ARTICLE_OPTIONS,
} from "../../../src/utils/values";

export default {
  components: {},
  data() {
    return {
      platforms: [
        { value: null, text: "Please select a platform" },
        ...Object.keys(platformOptions).map((platform) => ({
          value: platform,
          text: platform.charAt(0).toUpperCase() + platform.slice(1),
        })),
      ],
      deviceTypes: [
        { value: null, text: "Please select a device type" },
        { value: "mobile", text: "Mobile" },
        { value: "desktop", text: "Desktop" },
      ],
      layouts: LAYOUT_OPTIONS,
      videoPlayerOptions: VIDEO_PLAYER_OPTIONS,
      endOfArticleOptions: END_OF_ARTICLE_OPTIONS,
      stickyEnabled: true,
      form: {
        name: "",
        layout: null,
        platform: null,
        device_type: null,
        sticky_display: 8,
        sticky_unit_refresh: null,
        sticky_time_refresh: null,
        inter: null,
        single_ad: null,
        double_ad: null,
        end_of_article: null,
        video_player: null,
        is_default: false,
      },
      inProgress: false,
      submitError: null,
    };
  },
  mounted() {
    if (this.$route.params.templatePreset) {
      this.form = this.$route.params.templatePreset;
    }
  },
  computed: {
    selectedLayout() {
      return this.layouts.find((l) => l.value === this.form.layout);
    },
    selectedVP() {
      return this.videoPlayerOptions.find(
        (vp) => vp.value == this.form.video_player
      );
    },
    selectedEndOfArticle() {
      return this.endOfArticleOptions.find(
        (eoa) => eoa.value == this.form.end_of_article
      );
    },
    presetName() {
      return `${this.platformToPresetName()} (${this.videoPlayerToPresetName()}${this.stickyToPresetName()}${this.endOfArticleToPresetName()}${this.interToPresetName()}${this.adsToPresetName()}${this.layoutToPresetName()})`;
    },
  },
  watch: {
    presetName(presetName) {
      this.form.name = presetName;
    },
    stickyEnabled(enabled) {
      if (!enabled) {
        this.form.sticky_display = -1;
        this.form.sticky_unit_refresh = null;
        this.form.sticky_time_refresh = null;
      } else {
        this.form.sticky_display = 8;
      }
    },
  },
  methods: {
    onSubmit() {
      this.submitError = null;
      this.inProgress = true;

      this.$http
        .post(
          `${this.host}/monetization_presets`,
          { monetization_preset: this.form },
          { timeout: 100000 }
        )
        .then(() => {
          this.$router.push({
            name: "monetization-presets",
          });
        })
        .catch((ex) => {
          this.submitError = ex.response.data.error;
          this.inProgress = false;
        });
    },
    platformToPresetName() {
      return this.form.platform ? this.form.platform : "";
    },
    videoPlayerToPresetName() {
      return this.form.video_player ? `vp = ${this.selectedVP.text} |` : "";
    },
    stickyToPresetName() {
      if (!this.stickyEnabled) return " No Sticky |";

      return ` Sticky { stid = ${this.form.sticky_display}${
        this.form.sticky_unit_refresh
          ? `, unit_refresh: ${this.form.sticky_unit_refresh}`
          : ""
      }${
        this.form.sticky_time_refresh
          ? `, time_refresh: ${this.form.sticky_time_refresh}`
          : ""
      } } |`;
    },
    endOfArticleToPresetName() {
      return this.form.end_of_article
        ? ` EOA = ${this.selectedEndOfArticle.text} |`
        : "";
    },
    interToPresetName() {
      return this.form.inter ? ` inter = ${this.form.inter} |` : "";
    },
    adsToPresetName() {
      return this.form.single_ad || this.form.double_ad
        ? ` ads = ${this.form.single_ad}/${this.form.double_ad} |`
        : "";
    },
    layoutToPresetName() {
      return this.form.layout ? ` ${this.selectedLayout.shortName}` : "";
    },
  },
};
</script>
