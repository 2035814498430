<template>
  <div>
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>

    <vs-button
      size="small"
      class="mb-2 btn-sm"
      color="primary"
      @click="newMonetizationPreset()"
    >
      New Preset
    </vs-button>
    <vs-card>
      <div v-if="loading" class="d-flex justify-content-center m-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div v-if="!loading">
        <div class="d-flex align-items-center input-container p-3">
          <label for="platform" class="input-control mr-3 mb-0">Platform</label>
          <select v-model="selectedPlatform" id="platform" class="form-control">
            <option>All</option>
            <option
              v-for="platform in platforms"
              :value="platform"
              :key="platform"
            >
              {{ platform }}
            </option>
          </select>
          <label for="device_type" class="input-control mx-3 mb-0"
            >Device type</label
          >
          <select
            v-model="selectedDeviceType"
            id="device_type"
            class="form-control"
          >
            <option>All</option>
            <option
              v-for="device_type in device_types"
              :value="device_type"
              :key="device_type"
            >
              {{ device_type }}
            </option>
          </select>
        </div>

        <b-table
          :items="filteredPresets"
          :fields="tableHeaders"
          responsive="md"
        >
          <template v-slot:cell(name)="row">
            {{ row.item.name }}
            <b-badge v-if="row.item.is_default" pill variant="primary"
              >Default</b-badge
            >
          </template>
          <template v-slot:cell(show_details)="row">
            <b-button size="md" @click="row.toggleDetails"
              >{{ row.detailsShowing ? "Hide" : "Show" }} Details</b-button
            >
          </template>
          <template v-slot:cell(duplicate)="row">
            <b-button
              class="btn-sm"
              pill
              @click="newMonetizationPreset(row.item)"
              variant="outline-primary"
            >
              <i class="fa fa-plus"></i>
            </b-button>
          </template>
          <template v-slot:cell(delete)="row">
            <b-button
              class="btn-sm"
              pill
              @click="deletePreset(row.item)"
              variant="outline-danger"
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </template>

          <template v-slot:row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Sticky display:</b>
                </b-col>
                <b-col>{{ row.item.sticky_display }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Sticky unit refresh:</b>
                </b-col>
                <b-col>{{ row.item.sticky_unit_refresh }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Sticky time refresh:</b>
                </b-col>
                <b-col>{{ row.item.sticky_time_refresh }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Interstitial display:</b>
                </b-col>
                <b-col>{{ row.item.inter }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Single ad from item:</b>
                </b-col>
                <b-col>{{ row.item.single_ad }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Double ad from item:</b>
                </b-col>
                <b-col>{{ row.item.double_ad }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Video Player:</b>
                </b-col>
                <b-col>{{ row.item.video_player }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>End of Article:</b>
                </b-col>
                <b-col>{{ endOfArticleName(row.item.end_of_article) }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Layout:</b>
                </b-col>
                <b-col>{{ layoutName(row.item.layout) }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col md="3" class="text-md-right">
                  <b>Custom:</b>
                </b-col>
                <b-col>{{ row.item.custom }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
    </vs-card>
  </div>
</template>

<script>
import platformOptions from "../../../src/utils/platform_options";
import {
  LAYOUT_OPTIONS,
  END_OF_ARTICLE_OPTIONS,
} from "../../../src/utils/values";

export default {
  components: {},
  data() {
    return {
      monetizationPresets: [],
      tableHeaders: [
        "id",
        "name",
        { key: "file_name", label: "Code" },
        "platform",
        { key: "device_type", label: "Device Type" },
        { key: "show_details", label: "" },
        { key: "duplicate", label: "" },
        { key: "delete", label: "" },
      ],
      platforms: Object.keys(platformOptions),
      selectedPlatform: "All",
      device_types: ["mobile", "desktop"],
      selectedDeviceType: "All",
      loading: true,
      layouts: LAYOUT_OPTIONS,
      endOfArticles: END_OF_ARTICLE_OPTIONS,
      submitError: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.get(`${this.host}/monetization_presets`).then((res) => {
        this.loading = false;
        this.monetizationPresets = res.data;
      });
    },
    deletePreset(preset) {
      if (confirm(`You are about to delete the preset "${preset.name}".\n\nAre you sure?`)) {
        this.submitError = null;

        this.$http
          .delete(`${this.host}/monetization_presets/${preset.id}`)
          .then(() => {
            this.monetizationPresets = this.monetizationPresets.filter(
              (p) => p.id !== preset.id
            );
          })
          .catch((ex) => {
            this.submitError = ex.response.data.error;
          });
      }
    },
    newMonetizationPreset(templatePreset = null) {
      this.$router.push({
        name: "new-monetization-preset",
        params: { templatePreset: templatePreset },
      });
    },
    layoutName(layout) {
      if (layout == null) return "-";

      return this.layouts.find((l) => l.value === layout).text;
    },
    endOfArticleName(endOfArticle) {
      if (endOfArticle == null) return "-";

      return this.endOfArticles.find((eoa) => eoa.value === endOfArticle).text;
    },
  },

  computed: {
    filteredPresets: function () {
      let filteredPresets = this.monetizationPresets;

      if (this.selectedPlatform !== "All") {
        filteredPresets = filteredPresets.filter(
          ({ platform }) => platform === this.selectedPlatform
        );
      }
      if (this.selectedDeviceType !== "All") {
        filteredPresets = filteredPresets.filter(
          ({ device_type }) => device_type === this.selectedDeviceType
        );
      }

      return filteredPresets;
    },
  },
};
</script>
