<template>
  <div>
    <button
      :id="`range-start-button-${vueComponentId}`"
      class="range-button"
      type="button"
      @click="togglePopover"
    >
      <div v-if="isRangeRelative">
        {{ humanizedRange }}
      </div>
      <div v-else>
        <span class="mr-1">{{ `${eventByValue[range.value]}` }}</span>
        <sup v-if="isSignedOffset">{{ offsetString }}</sup>
      </div>
    </button>

    <b-popover
      placement="bottom"
      triggers="click blur"
      :target="`range-start-button-${vueComponentId}`"
      :show="show"
    >
      <b-tabs content-class="mt-3">
        <b-tab
          :title="capitalize(RANGE_MODE_RELATIVE)"
          :active="isRangeRelative"
        >
          <time-picker
            :value="isRangeRelative ? range.value : null"
            @input="
              (value) => updateRange(value, RANGE_MODE_RELATIVE, range.offset)
            "
            @keyup.native.enter="(event) => event.target.blur()"
        /></b-tab>
        <b-tab
          :title="capitalize(RANGE_MODE_EVENT)"
          :active="isRangeEventBased"
        >
          <div class="d-flex flex-row items-center">
            <small class="mr-2">Event</small>
            <b-select
              size="sm"
              :options="eventsOptions"
              :value="isRangeEventBased ? range.value : null"
              @input="
                (value) => updateRange(value, RANGE_MODE_EVENT, range.offset)
              "
              @keyup.native.enter="(event) => event.target.blur()"
            ></b-select>
          </div>
          <br />
          <div class="d-flex flex-row items-center">
            <small class="mr-2">Offset</small>
            <time-picker
              :value="range.offset"
              @input="(value) => updateRange(range.value, range.mode, value)"
              @keyup.native.enter="(event) => event.target.blur()"
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-popover>
  </div>
</template>

<script>
import moment from "moment";
import { capitalize } from "lodash/string";

import rangeHelper from "./range-helper";
import TimePicker from "../TimePicker.vue";

export default {
  mixins: [rangeHelper],

  props: {
    eventsOptions: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [Object, Number],
      default: 0,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  components: { TimePicker },

  data() {
    const eventByValue = this.eventsOptions.reduce((obj, current) => {
      obj[current.value] = current.text;
      return obj;
    }, {});

    const rangeProp = this.value;
    const range = Number.isInteger(rangeProp)
      ? this.createRange(rangeProp)
      : this.createRange(rangeProp.value, rangeProp.mode, rangeProp.offset);

    return {
      range,
      vueComponentId: this._uid,
      eventByValue,
    };
  },

  computed: {
    humanizedRange() {
      const time = this.range.value;
      return (time && `${this.humanizedDuration(time)} ago`) || "Now";
    },

    isRangeRelative() {
      return this.range.mode === this.RANGE_MODE_RELATIVE;
    },

    isRangeEventBased() {
      return this.range.mode === this.RANGE_MODE_EVENT;
    },

    isSignedOffset() {
      return this.range.offset != 0;
    },

    offsetString() {
      const mathSignToSign = { 1: "+", "-1": "-", 0: "" };
      return `${
        mathSignToSign[Math.sign(this.range.offset)]
      }${this.humanizedDuration(this.range.offset)}`;
    },
  },

  methods: {
    togglePopover() {
      this.$emit("input:show", !this.isPopoverVisible);
    },

    updateRange(value, mode, offset) {
      if (value == null) return;

      this.$emit(
        "input",
        Object.assign(this.range, this.createRange(value, mode, offset))
      );
    },

    capitalize,

    humanizedDuration(time) {
      return moment.duration(time * 1000).humanize();
    },
  },
};
</script>

<style scoped>
@layer components {
  .range-button {
    @apply p-2 border-solid border-0 border-b rounded w-64 bg-transparent cursor-pointer;
  }
}
</style>
