<template>
  <div>    
    <div class="row">
      <div class="col">
        <vs-button size="small" class="mb-2 btn-sm" color="primary" @click="$router.push({name: 'push-audiences-new'})">New Audience</vs-button>      
        <vs-card>
          <div>
            <table class="tablemobile text-left table-bordered table">
              <thead>
                <tr>
                  <th>
                    Publisher
                  </th>
                  <th>
                    Name
                  </th>
                  <th>
                    Filter
                  </th>                  
                  <th>
                    Created
                  </th>
                  <th>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="audience in audiences" :key="audience.name">
                  <td>{{ audience.name }}</td>
                  <td><div v-for="f in audience.filter" :key="f.value">{{ f.type }} {{ f.operator }} {{ f.value }}</div></td>
                  <td>{{ audience.domain }}</td>
                  <td>{{ moment(audience.createdAt) }}</td>
                  <td><b-button :disabled="audienceBeingDeleted === audience._id" @click="deleteAudience(audience)" size="sm" variant="danger">{{ audienceBeingDeleted === audience._id ? "Deleting..." : "Delete" }}</b-button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-pagination pills v-model="currentPage" align="center" :total-rows="total" :per-page="perPage"></b-pagination>
          <div class="text-center">Audiences per page: {{ perPage }} - Total Audiences: {{ total }}</div>
        </vs-card>
      </div>
    </div>
  </div>
</template>

<script>  
  import numeral from 'numeral'
  import moment from 'moment'

  export default {
    data() {
      return {
        numeral: numeral,
        moment: moment,
        audiences: [],
        audienceBeingDeleted: null,
        perPage: 20,
        currentPage: 1,
        total: 0
      }
    },
    mounted() {
      this.getData();
    },
    watch: {
      currentPage: function() {
        this.getData()
      }
    },
    methods: {
      getData() {  
        let params = { limit: this.perPage, offset: ( this.currentPage - 1 ) * this.perPage }    
        this.$http.get(`${this.host}/push/audiences`, { params }).then(res => {
          this.audiences = res.data.audiences
          this.total = res.data.total
        })
      },
      newAudience(){
        this.$router.push({ name: 'push-audiences-new'})
      },
      deleteAudience(audience){
        this.audienceBeingDeleted = audience._id
        this.$http.put(`${this.host}/push/${audience.publisher}/delete_audience`, { audience_id: audience._id })
          .then(() => {
            this.getData();
          }).finally(() => this.audienceBeingDeleted = null)
      }
    },
    components: {

    }
  }
</script>

<style>

.sticky {
  position: inline;
  transform: translateY(-3.5rem);
  margin-bottom: -3.5rem;
  
}

</style>
