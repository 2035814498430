<template>
  <vs-card>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <h4>Daquan's Subscribers {{ numeral(getTotal('Daquan')).format('0,0') }}</h4>
        <vue-apex-charts ref="apexChartDaquan" type="area" height="250" width='100%' :options="chartOptions" :series="chartDataDaquan" />
      </div>
      <div class="col-md-6 col-sm-12">
        <h4>Comedy's Subscribers {{ numeral(getTotal('Comedy')).format('0,0') }}</h4>
        <vue-apex-charts ref="apexChartComedy" type="area" height="250" width='100%' :options="chartOptions" :series="chartDataComedy" />
      </div>
      <div class="col-md-6 col-sm-12">
        <h4>Journal's Subscribers {{ numeral(getTotal('Journal')).format('0,0') }}</h4>
        <vue-apex-charts ref="apexChartJournal" type="area" height="250" width='100%' :options="chartOptions" :series="chartDataJournal" />
      </div>
    </div>
  </vs-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'  
  import numeral from 'numeral'

  export default {
    data() {
      return {
        numeral: numeral,
        stats: {},
        subscribers: {},
        chartDataComedy: [{
          type: 'area',
          name: '',
          data: []
        }],
        chartDataDaquan: [{
          type: 'area',
          name: '',
          data: []
        }],
        chartDataJournal: [{
          type: 'area',
          name: '',
          data: []
        }],
        publishers: [{
          id: 1,
          name: "Daquan"          
        },
        {
          id: 2,
          name: "Comedy"
        },
        {
          id: 3,
          name: "Journal"
        }
        ],
        chartOptions: {
          grid: {
            show: true
          },          
          chart: {                      
            toolbar: {
              show: false,
            }            
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 2.5
          },
          fill: {
            type: 'gradient'            
          },
          xaxis: {
            type:'datetime',
            lines: {
              show: false,
            },            
            labels: { show: true }
          },
          yaxis: [{

          }],
          tooltip: {
            x: {
              format: 'dd MMM yyyy HH:mm'
            }
          }
        }
      }
    },
    watch: {

    },
    mounted() {
      this.getData();
    },
    methods: { 
      getTotal(pub)
      {
        if (!this.subscribers[pub])
          return;

        return this.subscribers[pub].total;
      },    
      getData() {
        this.publishers.forEach(pub => {     
          this.$http.get(`${this.host}/push/${pub.id}/subscribers`, { params: { topic: 'all' }}).then(res => {
            this.subscribers[pub.name] = res.data;
            this.$forceUpdate();
          })  

          this.$http.get(`${this.host}/push/${pub.id}/stats`)
          .then(response => {   

            this.stats[pub.name] = response.data.stats;

            let views = [
            { name: 'New Subscriptions', type: 'area', data: response.data.stats.map(d => { return [d.date * 1000, d.count] }) }            
            ];

            this.$refs[`apexChart${pub.name}`].updateSeries(views)
            this.$forceUpdate();
          })
        })
      }
    },
    components: {
      VueApexCharts
    }
  }
</script>

<style>

</style>
