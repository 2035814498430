<template>
  <b-container>
    <div class="d-flex justify-end mb-3">
      <vx-tooltip text="Create" position="bottom" delay=".3s">
        <feather-icon class="cursor-pointer" icon="PlusCircleIcon" @click="$router.push('rules/new')" />
      </vx-tooltip>
    </div>

    <div class="w-100 h-100" v-if="loading">
      <b-spinner></b-spinner>
    </div>

    <b-container fluid v-else>
      <b-list-group>
        <b-list-group-item v-for="(rule, index) in rules" :key="index">
          <div class="d-flex justify-content-between">
            <h5 class="mb-1 mr-auto">{{ rule.name }}</h5>
            <div v-if="compareRunStatus(rule, RUN_STATUSES.success)">
              <feather-icon icon="CheckCircleIcon" style="color: #286928" />
            </div>
            <div v-else-if="compareRunStatus(rule, RUN_STATUSES.fail)">
              <feather-icon icon="XCircleIcon" style="color: #c53030" />
            </div>
            <div v-else-if="compareRunStatus(rule, RUN_STATUSES.pending)">
              <b-spinner small class="h-6 w-6"></b-spinner>
            </div>
            <vx-tooltip v-else-if="compareRunStatus(rule, RUN_STATUSES.idle)" text="Run" position="bottom" delay=".3s">
              <feather-icon class="cursor-pointer" icon="PlayCircleIcon" @click="() => {
                changeRunStatus(rule, RUN_STATUSES.pending);
                runRule(rule.id)
                  .then(() => changeRunStatus(rule, RUN_STATUSES.success))
                  .catch(() => changeRunStatus(rule, RUN_STATUSES.fail))
                  .finally(() => setTimeout(() => changeRunStatus(rule, RUN_STATUSES.idle), 3000));
              }" />
            </vx-tooltip>
            <vx-tooltip class="ml-4" text="Edit" position="bottom" delay=".3s">
              <feather-icon class="cursor-pointer" icon="ArrowRightCircleIcon"
                @click="$router.push(`rules/${rule.id}`)" />
            </vx-tooltip>
          </div>

          <p class="mb-1">
            {{ rule.description }}
          </p>

          <small>Active: {{ rule.start_time }} - {{ rule.end_time }}</small>
          <br />
          <small>Cooldown: {{ rule.cooldown_time }}</small>

          <div class="d-flex w-100 justify-content-between">
            <b-form-checkbox v-model="rule.is_active" size="lg" :switch="true" :disabled="true"></b-form-checkbox>

            <vx-tooltip text="Delete" position="bottom" delay=".3s">
              <feather-icon style="color: #c53030" class="cursor-pointer" icon="Trash2Icon"
                @click="deleteRule(rule.id)" />
            </vx-tooltip>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-container>
  </b-container>
</template>

<script>
import moment from "moment";

import FeatherIcon from "../components/FeatherIcon.vue";
import { formatTime } from "../utils/rules";

const RUN_STATUSES = {
  success: 'success',
  fail: 'fail',
  idle: 'idle',
  pending: 'pending'
};

export default {
  components: { FeatherIcon },
  data() {
    return {
      loading: true,
      rules: [],
      RUN_STATUSES
    };
  },

  created() {
    this.getRules();
  },

  methods: {
    async deleteRule(ruleId) {
      const deleteConfirmed =
        prompt(
          "Are you sure you want to delete this rule? (yes/no)",
          "No"
        )?.toLowerCase() === "yes";

      if (deleteConfirmed) {
        await this.$http.delete(`${this.host}/rules/${ruleId}`);
        this.getRules();
      }
    },

    async getRules() {
      const { data: rules } = await this.$http.get(`${this.host}/rules`);

      this.rules = rules.map((rule) => {
        const formatCooldown =
          rule.cooldown_time != 0
            ? moment.duration(rule.cooldown_time * 1000).humanize()
            : "-";

        return {
          ...rule,
          start_time: formatTime(rule.start_time),
          end_time: formatTime(rule.end_time),
          cooldown_time: formatCooldown,
          runStatus: RUN_STATUSES.idle
        };
      });

      this.loading = false;
    },

    async runRule(ruleId) {
      await this.$http.post(`${this.host}/rules/${ruleId}/run`);
    },

    changeRunStatus(rule, status) {
      Object.assign(rule, { runStatus: status });

    },

    compareRunStatus(rule, status) {
      return rule.runStatus === status;
    },

    setTimeout() {
      setTimeout(...arguments);
    }
  },
};
</script>
