<template>
  <vs-card>    
    <div>
      <table class="table-bordered table">
        <thead>
          <tr>
            <th>
              Time
            </th>
            <th>
              Campaign
            </th>
            <th>
              Message
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="notification in notifications" :key="notification.id">
            <td>
              {{ moment(notification.created_at) }}
            </td>  
            <td>
              {{ notification.campaign.display_name }}
            </td>
            <td class="text-danger">
              {{ notification.message }}
            </td>            
          </tr>
        </tbody>
      </table>
      <vs-button v-if="hasMore" color="danger" size="small" @click="getData()">Load More</vs-button>
    </div>
  </vs-card>
</template>

<script>
  import moment from 'moment';

  export default {
    components: {
      
    },
    data() {
      return {
        notifications: [],
        offset: 0,
        limit: 15,
        moment: moment,
        hasMore: true
      }
    },
    mounted() {
      this.getData();
    },
    methods: {      
      getData() {        
        this.$http.get(`${this.host}/notifications`, { params: { offset: this.offset, limit: this.limit }}).then(res => {
          this.notifications = this.notifications.concat(res.data.notifications)
          this.offset += res.data.notifications.length;
          if (res.data.notifications.length < this.limit)
            this.hasMore = false;
        })                
      }
    }
  }
</script>

<style>

</style>
