<template>
  <b-container>
    <div class="d-flex justify-end gap-8 mb-3">
      <vx-tooltip v-if="ruleId" text="Duplicate" position="bottom" delay=".3s">
        <feather-icon
          class="cursor-pointer"
          icon="CopyIcon"
          @click="duplicateRule"
        />
      </vx-tooltip>
      <vx-tooltip text="Back" position="bottom" delay=".3s">
        <feather-icon
          class="cursor-pointer"
          icon="ArrowLeftCircleIcon"
          @click="$router.push(`/rules`)"
        />
      </vx-tooltip>
    </div>

    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>

    <div v-else>
      <b-form @submit="onSubmit">
        <b-form-checkbox switch v-model="form.is_active"
          >Active</b-form-checkbox
        >

        <br />

        <b-form-group>
          <label>Name</label>
          <b-form-input
            class="input-underscore"
            type="text"
            v-model="form.name"
            placeholder="Give it a name..."
            required
          />
        </b-form-group>

        <b-form-group>
          <label>Description</label>
          <b-form-textarea
            class="input-underscore"
            rows="3"
            max-rows="20"
            v-model="form.description"
            placeholder="Give a short description..."
          />
        </b-form-group>

        <b-form-group>
          <label>Starts at</label>
          <b-form-timepicker
            class="input-underscore"
            minutes-step="15"
            v-model="form.start_time"
          />
        </b-form-group>

        <b-form-group>
          <label>Ends at</label>
          <b-form-timepicker
            class="input-underscore"
            minutes-step="15"
            v-model="form.end_time"
          />
        </b-form-group>

        <b-form-group description="leave blank for every minute.">
          <label>Runs every</label>
          <div class="w-1/2">
            <time-picker
              class="input-underscore"
              v-model="form.cooldown_time"
            />
          </div>
        </b-form-group>

        <b-form-group label="Entity" description="what we are talking about.">
          <b-form-select
              @change="updateEntityFields(form.entity)"
              class="input-underscore"
              type="text"
              v-model="form.entity"
              :options="entities"
              :disabled="isChangeEntityDisabled"
              required
            >
          </b-form-select>
        </b-form-group>

        <b-form-group :disabled="isEntityEmpty">
          <h5>Entity filter</h5>
          <query-builder
            class="input-underscore"
            :rules="mapped_entity_fields"
            :labels="rulesLabels"
            :max-depth="maxDepth"
            v-model="form.entity_filter"
          >
          </query-builder>
        </b-form-group>

        <b-form-group :disabled="isEntityEmpty">
          <div class="d-flex align-middle mt-1 items-center">
            <h5>Metrics filter</h5>
            <vx-tooltip
              text="Note: Event mode can only be used when Entity Filter is entered."
              position="right"
            >
              <feather-icon
                class="ml-1 mr-1"
                svgClasses="w-4 h-4"
                icon="HelpCircleIcon"
              />
            </vx-tooltip>
          </div>
          <query-builder
            class="input-underscore"
            :max-depth="maxDepth"
            :rules="mapped_metrics_fields"
            :labels="rulesLabels"
            :with-range="true"
            :customRangeOptions="entityFieldsOptions"
            v-model="form.metrics_filter"
          >
          </query-builder>
        </b-form-group>

        <b-form-group>
          <h5>Actions</h5>
          <div class="flex flex-row items-center gap-x-4">
            <div
              class="flex flex-start flex-row gap-x-4 pt-5 px-5 overflow-x-auto overflow-y-hidden"
            >
              <vs-card
                type="4"
                class="w-fit h-48 p-1"
                v-for="(action, index) in form.actions"
                :key="index"
              >
                <div class="flex justify-between flex-row gap-4 mb-2">
                  <b-select
                    size="md"
                    class="input-underscore w-fit"
                    v-model="action.action_type"
                    :options="getLabelOptions('action_types', action_types)"
                    placeholder="Select"
                  ></b-select>
                  <feather-icon
                    class="cursor-pointer delete-red"
                    icon="XIcon"
                    @click="() => removeAction(index)"
                  />
                </div>
                <div
                  class="p-2 w-48"
                  :hidden="
                    category_by_action_type[action.action_type] == 'basic'
                  "
                >
                  <label class="mb-0">Field</label>
                  <b-select
                    v-model="action.key"
                    size="sm"
                    class="input-underscore mb-2"
                    :options="getLabelOptions('entity_fields', entity_fields)"
                  />
                  <label class="mb-0">Value</label>
                  <div class="flex flex-row">
                    <b-input
                      v-model="action.value"
                      size="sm"
                      class="input-underscore"
                    />
                    <label v-if="action.action_type.includes('by_percentage')"
                      >%</label
                    >
                  </div>
                </div>
              </vs-card>
            </div>
            <vx-tooltip text="New Action" position="bottom" delay=".3s">
              <feather-icon
                class="cursor-pointer"
                icon="PlusCircleIcon"
                @click="createAction"
              />
            </vx-tooltip>
          </div>
        </b-form-group>

        <b-button type="submit" variant="primary">Save</b-button>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import merge from "lodash/merge";
import QueryBuilder from "./Rules/QueryBuilder/QueryBuilder.vue";
import TimePicker from "./Rules/TimePicker.vue";
import {
  formatTime,
  convertServerJsonToForm,
  convertFormJsonToServer,
} from "../utils/rules";

const AND = "AND";
const OR = "OR";

export default {
  components: {
    QueryBuilder,
    TimePicker,
  },

  data() {
    const operatorsMapping = {
      "=": "equals",
      "<": "less than",
      "<=": "less than or equals",
      "~": "like",
      ">": "more than",
      ">=": "more than or equals",
    };

    return {
      ruleId: this.$route.params.id,
      rulesLabels: {
        matchType: "Logical operator",
        matchTypes: [
          { id: AND.toLowerCase(), label: AND },
          { id: OR.toLowerCase(), label: OR },
        ],
        addRule: "Add Condition",
        textInputPlaceholder: "Enter value",
        operatorsMapping,
      },
      operators: Object.keys(operatorsMapping),
      maxDepth: 8,
      form: {
        name: "",
        description: "",
      },
      labels: {},
      entity_fields: [],
      date_entity_fields: [],
      mapped_entity_fields: [],
      date_entity_fields: [],
      mapped_metrics_fields: [],
      loading: true,
    };
  },

  mounted() {
    const promises = [];

    promises.push(this.fetchStaticFields());

    if (this.ruleId) {
      const rulePromise = this.fetchRule(this.ruleId);
      promises.push(rulePromise);
    }

    Promise.all(promises).then(() => {
      if (this.form.entity) return this.updateEntityFields(this.form.entity)
    }).then(() => this.loading = false);
  },

  computed: {
    isEntityEmpty() {
      return !this.form.entity;
    },

    entityFieldsOptions() {
      return this.getLabelOptions('entity_fields', this.date_entity_fields);
    },

    isChangeEntityDisabled() {
      const { entity_filter, metrics_filter } = this.form;
      const isEntityFilterFilled = entity_filter && entity_filter.children.length > 0;
      const isMetricsFilterFilled = metrics_filter && metrics_filter.children.length > 0;
      return isEntityFilterFilled || isMetricsFilterFilled;
    }
  },

  methods: {
    async onSubmit() {
      const serverEntityFilter =
        this.form.entity_filter &&
        convertFormJsonToServer({
          query: this.form.entity_filter,
        });
      const serverMetricsFilter =
        this.form.metrics_filter &&
        convertFormJsonToServer({
          query: this.form.metrics_filter,
        });
      const payload = {
        ...this.form,
        entity_filter: serverEntityFilter,
        metrics_filter: serverMetricsFilter,
      };

      try {
        if (this.ruleId) {
          await this.$http.put(`${this.host}/rules/${this.ruleId}`, payload);
        } else {
          await this.$http.post(`${this.host}/rules`, payload);
        }

        alert("Saved");
        this.$router.push("/rules");
      } catch (err) {
        alert("Error saving");
        throw err;
      }
    },

    getLabelOptions(labelsName, optionsValues) {
      return optionsValues.map((value) => ({
        text: this.labels[labelsName][value],
        value: value,
      }));
    },

    async duplicateRule() {
      this.ruleId = null;
      this.form.name = `${this.form.name} - Copy`;
      await this.$router.push(`/rules/new`);
      alert("Duplicated");
    },

    createAction() {
      const action = Object.assign({}, this.defaults.action);
      this.form.actions.push(action);
    },

    removeAction(index) {
      this.form.actions.splice(index, 1);
    },

    async fetchRule(id) {
      const { data: rule } = await this.$http.get(`${this.host}/rules/${id}`);
      this.form = {
        ...rule,
        entity_filter: convertServerJsonToForm(rule.entity_filter),
        metrics_filter: convertServerJsonToForm(rule.metrics_filter),
        start_time: formatTime(rule.start_time),
        end_time: formatTime(rule.end_time),
      };
    },

    async fetchStaticFields() {
      const { data: fields } = await this.$http.get(
        `${this.host}/rules/static_fields`
      );

      merge(this, fields);
      Object.assign(this, {
        action_types: Object.keys(fields.category_by_action_type),
      });

      this.form = {
        // server defaults + client defaults
        ...this.defaults.rule,
        actions: [this.defaults.action],
        ...this.form,
      };
    },

    async updateEntityFields(entity) {
      const { data: fields } = await this.$http.get(
        `${this.host}/rules/entity_fields?entity=${entity}`
      );

      const { labels, ...other } = fields;
      Object.assign(this, other);
      Object.assign(this.labels, labels);
      Object.assign(this, {
        entity_fields: Object.keys(fields.type_by_entity_field),
        date_entity_fields: Object.entries(fields.type_by_entity_field)
          .filter(([k, v]) => v === "datetime" && k.includes(entity))
          .map(([k, _]) => k),
      });

      this.mapped_entity_fields = this.mapFields(
        this.type_by_entity_field,
        this.labels["entity_fields"]
      );
      this.mapped_metrics_fields = this.mapFields(
        this.type_by_metric_field,
        this.labels["metric_fields"]
      );
    },

    mapFields(fields, labelsMap) {
      const { operators } = this;
      const componentByType = {
        datetime: TimePicker,
      };

      return Object.entries(fields)
        .map(([field, type]) => ({
          operators,
          inputType: (componentByType[type] && "custom-component") || "text",
          id: field,
          label: labelsMap[field],
          component: componentByType[type],
          componentProps: {
            description: "ago",
          },
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
  },
};
</script>

<style scoped>
@layer components {
  .input-underscore {
    @apply border-b border-r-0 border-l-0 border-t-0 shadow-none;
  }

  .input-underscore:focus {
    box-shadow: none;
  }
}

.w-fit {
  width: fit-content;
}

.delete-red {
  color: #c53030;
}
</style>
