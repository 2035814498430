<template>
  <div class="flex space-between">
    <b-input
      type="number"
      class="w-1/3 rounded-r-none border-r-0"
      size="sm"
      :step="step"
      :number="true"
      v-model="formattedValue"
      @focus="(event) => event.target.select()"
      @keyup.enter="(event) => updateValue(Number.parseInt(event.target.value))"
      v-on:input="(value) => updateValue(Number.parseInt(value))"
    />

    <b-select
      size="sm"
      class="w-fit rounded-l-none mr-1"
      v-model="unitSelect"
      :options="unitOptions"
      v-on:input="() => updateValue(formattedValue)"
    ></b-select>

    <div class="text-bottom">
      <small v-if="description">{{ description }}</small>
    </div>
  </div>
</template>

<script>
const DEFAULT_UNIT = "hours";

const unitsMap = {
  hours: 3600,
  minutes: 60,
  seconds: 1,
};

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },

    description: {
      type: String,
    },

    step: {
      type: Number,
      default: 1,
    },
  },

  data() {
    const unit = this.getBestUnit(this.value);

    return {
      unitSelect: unit,
      formattedValue: Math.trunc((this.value / unitsMap[unit]) * 100) / 100,
      unitOptions: Object.keys(unitsMap),
    };
  },

  methods: {
    getBestUnit(value) {
      for (const unit in unitsMap) {
        if (Number.isInteger(value / unitsMap[unit])) return unit;
      }
      return DEFAULT_UNIT;
    },

    updateValue(value) {
      this.$emit("input", value * unitsMap[this.unitSelect] || 0);
    },
  },
};
</script>

<style scoped>
/** Disable increment and decrement buttons of number input */

/* Non Firefox */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>