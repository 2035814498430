<template>
  <query-builder-group
    v-bind="{ ...vqbProps, withRange }"
    :query.sync="query"
  />
</template>


<script>
import VueQueryBuilder from "vue-query-builder/src/VueQueryBuilder.vue";
import deepMerge from "lodash/merge";

import QueryBuilderGroup from "./QueryBuilderGroup.vue";

import rangeHelper from "./range-helper";

export default {
  extends: VueQueryBuilder,

  mixins: [rangeHelper],

  components: { QueryBuilderGroup },

  props: {
    withRange: {
      type: Boolean,
      default: false,
    },

    customRangeOptions: {
      type: Array,
      default: () => [],
    },
  },

  provide() {
    return {
      withRange: this.withRange,
      customRangeOptions: this.customRangeOptions,
    };
  },

  data() {
    if (!this.withRange) return {};

    const rangeStart = this.value?.range?.start;
    const rangeEnd = this.value?.range?.end;

    return {
      query: {
        range: {
          start: rangeStart || this.createRange(0),
          end: rangeEnd || this.createRange(0),
        },
      },
    };
  },

  mounted() {
    // setting this because the component being extended watches changes only after 'mounted'
    if (this.$props.value) deepMerge(this.query, this.$props.value);
  },
};
</script>

<style>
</style>