<template>
  <vue-tags-input
    :separators="['\n']"
    :tags="tags"
    :placeholder="placeholder"
    v-model="newTag"
    @tags-changed="tagsChanged"
  />
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: { VueTagsInput },

  props: {
    value: {
      default: "",
    },

    placeholder: {
      type: String,
    },
  },

  data() {
    return {
      newTag: "",
      inputValue: this.value,
    };
  },

  computed: {
    tags: {
      get() {
        if (!this.inputValue) return [];

        return (
          typeof this.inputValue === "string"
            ? this.inputValue.split("\n")
            : [this.inputValue]
        ).map((value) => ({ text: value }));
      },

      set(tags) {
        this.inputValue = tags.map((obj) => obj.text).join("\n");
        this.$emit("input", this.inputValue);
      },
    },
  },

  methods: {
    tagsChanged(newTags) {
      this.tags = newTags;
    },
  },
};
</script>

<style>
.vue-tags-input .ti-input {
  border: 0;
  padding: 0;
}
</style>