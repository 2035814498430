<template>
  <div>
    <form>
      <vs-card class="col justify-content-center pt-6">
        <div class="form-group row justify-content-center">
          <label for="name" class="text-right col-sm-4 col-form-label">Name</label>
          <div class="col-sm-6">
            <input type="text" placeholder="New Audience" class="form-control" v-model="name"/>
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="publisher" class="text-right col-sm-4 col-form-label">Publisher</label>
          <div class="col-sm-6">
            <multiselect v-model="publisher" label="name"
                        track-by="name"
                        :multiple="false"
                        :searchable="false"
                        :allow-empty="false"
                        :options="publishers"></multiselect>
          </div>
        </div>
        <div v-if="publisher && options && !loading" class="form-group pt-4">
          <div class="form-group row justify-content-center">
            <label for="Operating System" class="text-right col-sm-4 col-form-label">Operating Systems</label>
            <div class="col-sm-6">
              <multiselect v-model="operatingSystems" label="value"
                          track-by="value"
                          :multiple="true" :searchable="false"
                          :options="multiSelectOptions('os_name')"></multiselect>
            </div>
          </div>
          <div class="form-group row justify-content-center">
            <label for="Operating System" class="text-right col-sm-4 col-form-label">Countries</label>
            <div class="col-sm-6">
              <multiselect v-model="countries" label="value"
                          track-by="value"
                          :multiple="true" :searchable="false"
                          :options="multiSelectOptions('country')"></multiselect>
            </div>
          </div>
          <div class="form-group row justify-content-center">
            <label for="Tags" class="text-right col-sm-4 col-form-label">Tags</label>
            <div class="col-sm-6">
              <multiselect v-model="tags" label="value"
                          track-by="value"
                          :multiple="true" :searchable="true"
                          :options="multiSelectOptions('tags')"></multiselect>
            </div>
          </div>
          <div class="form-group row justify-content-center">
            <label for="Campaigns" class="text-right col-sm-4 col-form-label">Campaigns (id)</label>
            <div class="col-sm-6">
              <multiselect v-model="campaignIds" label="value"
                          track-by="value"
                          :multiple="true" :searchable="true"
                          :options="multiSelectOptions('campaign_id')"></multiselect>
            </div>
          </div>
          <div class="form-group row justify-content-center">
            <label for="age_min" class="text-right col-sm-4 col-form-label">
              Ages
            </label>
            <div class="col-sm-3">
              <multiselect v-model="age_min" :options="ageOptions('age_min')"></multiselect>
            </div>
            -
            <div class="col-sm-3">
              <multiselect v-model="age_max" :options="ageOptions('age_max')"></multiselect>
            </div>
          </div>
        </div>
        <div v-else-if="loading" class="text-center">
          <b-spinner variant="primary"></b-spinner>
        </div>
      </vs-card>
    <b-button variant="success" :disabled="invalid || submiting" @click="create">{{ submiting ? 'Creating...' : 'Create' }}</b-button>
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
    </form>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { UTILS } from "../../utils/campaign_form.js"
import { PUBLISHERS } from "../../utils/product_entities.js"

export default {
  name: "NewAudience",
  data: function() {
    return {
      name: null,
      publisher: null,
      operatingSystems: [],
      countries: [],
      tags: [],
      campaignIds: [],
      age_max: null,
      age_min: null,
      options: null,
      submiting: false,
      submitError: null,
      loading: false
    }
  },
  watch: {
    publisher(n) {
      if (!n) return;

      this.resetFields()
      this.loading = true;

      this.$http.get(`${this.host}/push/${n.id}/tags`)
      .then(res => {
        this.options = { ...res.data.filtersType, tags: res.data.content_tags }
      }).finally(() => {
        this.loading = false
      })
    },
    age_min() {
      if (this.age_max <= this.age_min) this.age_max = null;
    }
  },
  computed: {
    invalid: function() {
      return !this.publisher || !this.name
    },
    publishers: function() {
      return PUBLISHERS
    }
  },
  methods: {
    multiSelectOptions(key) {
      if (!this.options || !this.options[key]) return [];

      return this.options[key].map(v => {return { value: v, operator: '='}})
    },
    ageOptions: function(key) {
      if (!this.options || !this.options[key]) return [];

      let dup = UTILS.clone(this.options[key])

      if (key == "age_max") dup = dup.filter(age => age > this.age_min || 0)

      return dup.sort()
    },
    resetFields(includeNamePub = false) {
      this.options = this.age_min = this.age_max = null;
      this.operatingSystems = this.countries = this.tags = this.campaignIds = [];

      if (includeNamePub) {
        this.publisher = null;
        this.name = null;
      }
    },
    create() {
      const params = {
        name: this.name,
        filters: {
          content_tags: this.tags,
          country: this.countries,
          os_name: this.operatingSystems,
          campaign_id: this.campaignIds,
          age_min: this.age_min ? [{ value: this.age_min, operator: '>'}] : [],
          age_max: this.age_max ? [{ value: this.age_max, operator: '<'}] : []
        }
      }

      this.$http.post(`${this.host}/push/${this.publisher.id}/create_audience`, params)
        .then(() => { 
          this.$router.push({name: "push-audiences"})
        }).catch(err => {
          this.submitError = err;
        }).finally(() =>{
          this.submiting = false;
        })
    }
  },
  components: {
    Multiselect
  }
}
</script>
<style>

</style>