const RANGE_MODE_RELATIVE = 'relative';
const RANGE_MODE_EVENT = 'event';

export default {
    data() {
        return {
            RANGE_MODE_RELATIVE,
            RANGE_MODE_EVENT
        };
    },

    methods: {
        createRange(value, mode = RANGE_MODE_RELATIVE, offset = 0) {
            return {
                mode,
                value,
                offset
            };
        }
    }
};
