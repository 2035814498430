<template>
  <div>
    <div class="flex mb-4">
      <label for="period" class="mt-2">Period</label>
      <date-range-picker
        class="form-control ml-2 w-64"
        v-model="range"
        :options="rangeOptions"
      />
    </div>
    <div v-if="isLoading" class="my-4">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else class="table-responsive">
      <div class="disclaimer">
        * bounce, pps and user value are Visits based
      </div>
      <table class="text-center table">
        <thead>
          <tr>
            <template v-for="header in Object.values(this.metricsColumns)">
              <th :key="header['label']">{{ header["label"] }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="publisherMetric in metrics">
            <tr :key="publisherMetric['key']" class="publisher-row">
              <template v-for="(value, key) in metricsColumns">
                <td :key="key">
                  {{
                    key == "key"
                      ? publisherMetric["key"].toUpperCase()
                      : numeral(publisherMetric[key] || 0).format(
                          value["format"]
                        )
                  }}
                </td>
              </template>
            </tr>
            <template v-for="oSmetric in publisherMetric['by_os']">
              <tr
                :key="`${publisherMetric['key']}-${oSmetric['key']}`"
                class="os-row"
              >
                <template v-for="(value, key) in metricsColumns">
                  <td :key="key" class="pl-6">
                    {{
                      key == "key"
                        ? oSmetric["key"].toUpperCase()
                        : numeral(oSmetric[key] || 0).format(value["format"])
                    }}
                  </td>
                </template>
              </tr>
              <template v-for="browserMetric in oSmetric['by_browser']">
                <tr
                  :key="
                    `${publisherMetric['key']}-${oSmetric['key']}-${browserMetric['key']}`
                  "
                >
                  <template v-for="(value, key) in metricsColumns">
                    <td :key="key" class="pl-12">
                      {{
                        key == "key"
                          ? browserMetric["key"].charAt(0).toUpperCase() +
                            browserMetric["key"].slice(1)
                          : numeral(browserMetric[key] || 0).format(
                              value["format"]
                            )
                      }}
                    </td>
                  </template>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
import moment from "moment-timezone";
import { datePickerRanges } from "../utils/values.js";

export default {
  name: "OSAndBrowser",
  data() {
    return {
      metrics: [],
      metricsColumns: {
        key: {
          label: "Publisher",
          format: null
        },
        lands: {
          label: "Visits",
          format: "0,0"
        },
        visits: {
          label: "Sessions",
          format: "0,0"
        },
        land_bounce: {
          label: "Bounce %",
          format: "0.0%"
        },
        imps_per_lands: {
          label: "PPS",
          format: "0,0.0"
        },
        land_user_value: {
          label: "User value",
          format: "$0,0.0000"
        },
        revenue: {
          label: "Revenue",
          format: "$0,0.000"
        },
        rpm: {
          label: "RPM",
          format: "$0,0.000"
        },
        units: {
          label: "Items",
          format: "0,0.0"
        }
      },
      isLoading: true,
      numeral: numeral,
      moment: moment,
      datePickerRanges: datePickerRanges,
      range: [moment().startOf("day"), moment().endOf("day")],
      rangeOptions: {
        alwaysShowCalendars: true,
        autoApply: true,
        maxSpan: { "days": 14 },
        maxDate: moment().add(1, "days"),
        ranges: datePickerRanges()
      },
      selectedDate: {
        start: moment().startOf("day"),
        end: moment().endOf("day")
      }
    };
  },
  mounted() {
    this.loadPublisherMetrics();
  },
  watch: {
    range: function(n) {
      let start = moment(n[0], "DD/MM/YYYY").startOf("day");
      let end = moment(n[1], "DD/MM/YYYY").endOf("day");
      this.$set(this, "selectedDate", { start, end });

      this.loadPublisherMetrics();
    }
  },
  methods: {
    loadPublisherMetrics() {
      this.isLoading = true;
      this.$http
        .get(`${this.host}/campaigns/publishers_os_and_browser`, {
          params: {
            start: this.selectedDate.start,
            end: this.selectedDate.end,
            timezone: localStorage.getItem("tz") || "UTC"
          }
        })
        .then(res => {
          this.metrics = res.data.metrics;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.disclaimer {
  margin: 0.5rem;
  font-style: italic;
  text-align: end;
}

.publisher-row {
  background-color: #0f163a;
  font-weight: bold;
  color: white;
}

.os-row {
  background-color: #0f163a;
  opacity: 0.5;
  color: white;
}
</style>
