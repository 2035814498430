<script>
import QueryBuilderChildren from "vue-query-builder/src/components/QueryBuilderChildren.vue";

import QueryBuilderGroup from "./QueryBuilderGroup.vue";
import QueryBuilderRule from "./QueryBuilderRule.vue";

export default {
  name: "QueryBuilderChildren",

  extends: QueryBuilderChildren,

  components: {
    QueryBuilderGroup,
    QueryBuilderRule,
  },
};
</script>

<style>
</style>